<template>
    <b-container id="Esencia" > 
    <div class="box" v-if="!isMobile()" >
        <div class="flex flex-column width-50 margin-right-5">
            <div class="flex align-items-center">
                <img class="responsive" src="/images/logoblack.png" alt="Joya Work Shop"> 
                <h2>{{ $t("essence-h2") }}</h2>
            </div>

            <div>
                <p>{{ $t("essence-p-1") }}</p>
                <p>{{ $t("essence-p-2") }}</p>
                <p>{{ $t("essence-p-3") }}</p>
            </div>
        
        </div>
        <div class="flex flex-column">
            
        </div>
        <div class="flex flex-column width-25 ">
            <img  class="rodajas"  src="/images/rodajas.png" alt="Rodajas">
        </div>
        
    </div>
    <div class="box" v-else>
        <div class="flex flex-column">
            <div class="flex align-items-center">
                <img class="responsive" src="/images/logoblack.png" alt="Joya Work Shop"> 
                <h2>{{ $t("essence-h2") }}</h2>
            </div>

            <div>
                <p>{{ $t("essence-p-1") }}</p>
                <p>{{ $t("essence-p-2") }}</p>
                <p>{{ $t("essence-p-3") }}</p>
            </div>
            <img  class="rodajas"  src="/images/rodajas.png" alt="Rodajas">
        </div>
    </div>
    </b-container>
</template>

<script>
    import customMixin from '@/mixins/customMixin'
    export default {
        mixins:[ 
            customMixin
        ],
    }
</script>

<style lang="scss" scoped>
#Esencia{
    display:flex;
    flex-direction:column;
    height: 100vh;
}
 .flex{
   display: flex;
 }
 .flex-column{
     flex-direction: column;
     
 }
 .flex-row{
    flex-direction: row;
    align-items:center;
     
 }
 .box{
    display:flex;
    align-items: center;
    justify-content: center;
    height:100vh;
    margin-top: -6vh;
    padding:0;
 }


.responsive {
    width: 100%;
    max-width: 140px;
    height: auto;
}
.rodajas {
    width:100%;
    height:auto;
    
}
p {
     text-align: justify;
}
.width-50{
    width: 50%;
}
.margin-right-5{
    margin-right: 3rem;
}
.width-25{
    width: 25%;
}
@media screen and (max-width:768px) {
    #Esencia{
        height:auto;
    }
    .box{
        margin-top:5vh;
        height:auto;
    }
    .responsive{
        width:30vw;
    }
    .width-50{
        width:100vw;
    }
    .margin-right-5{
        margin-right:auto;
    }
    .width-25{
        width:100vw;
    }
    .rojadas{
        width: 100vw;
        height: 80vh;
       
    }
}

</style>
