<template>
    <b-container id="Tips">
        <div class="box" v-if="!isMobile()">
            <div class="flex flex-column w-100 mt-3">
                <div class="flex flex-row align-items-center justify-content-between ">  
                    <div class="flex flex-row align-items-center">
                        <img class="responsive-logo" src="/images/logoblack.png" alt="Joya Work Shop"> 
                        <h2>{{ $t("tips-h2")}}</h2> 
                    </div>   
                </div>
                <div class="flex flex-row ml-5">
                    <h3>{{ $t("tips-h3")}}</h3>
                </div>
                <div class="flex flex-column align-items-start ml-5 mt-3">
                    <h4>{{ $t("tips-h4-1")}}</h4>
                    <p>{{ $t("tips-p1") }}</p>
                </div>
                <div class="flex flex-column align-items-start ml-5">
                    <h4>{{ $t("tips-h4-2")}}</h4>
                    <p>{{ $t("tips-p2") }}</p>
                </div>
                <div class="flex flex-column align-items-start ml-5">
                    <h4>{{ $t("tips-h4-3")}}</h4>
                    <p>{{ $t("tips-p3") }}</p>
                </div>
                <div class="flex flex-column align-items-start ml-5">
                    <h4>{{ $t("tips-h4-4")}}</h4>
                    <p>{{ $t("tips-p4") }}</p>
                </div>
                <div class="flex flex-column align-items-start ml-5">
                    <h4>{{ $t("tips-h4-5")}}</h4>
                    <p>{{ $t("tips-p5") }}</p>
                </div>
                <div class="flex flex-column align-items-start ml-5">
                    <h4>{{ $t("tips-h4-6")}}</h4>
                    <p>{{ $t("tips-p6") }}</p>
                </div>
                <div class="flex flex-column align-items-start ml-5">
                    <h4>{{ $t("tips-h4-7")}}</h4>
                    <p>{{ $t("tips-p7") }}</p>
                </div>
            </div>
        </div>
        <div  class="box" v-else>
            <div class="flex flex-column"> 
                <div class="flex flex-row align-items-center">
                        <img class="responsive-logo" src="/images/logoblack.png" alt="Joya Work Shop"> 
                        <h2>{{ $t("tips-h2")}}</h2> 
                </div>   
                <h3 class="mt-3 mb-5">{{ $t("tips-h3")}}</h3>
                 <div>
                    <h4>{{ $t("tips-h4-1")}}</h4>
                    <p>{{ $t("tips-p1") }}</p>
                </div>
                <div>
                    <h4>{{ $t("tips-h4-2")}}</h4>
                    <p>{{ $t("tips-p2")}}</p>
                </div>
               <div>
                    <h4>{{ $t("tips-h4-3")}}</h4>
                    <p> {{ $t("tips-p3")}}</p>
                </div>
                <div>
                    <h4>{{ $t("tips-h4-4")}}</h4>
                    <p>{{ $t("tips-p4")}}</p>
                </div>
                <div>
                    <h4>{{ $t("tips-h4-5")}}</h4>
                    <p>{{ $t("tips-p5")}}</p>
                </div>
                <div>
                    <h4>{{ $t("tips-h4-6")}}</h4>
                    <p>{{ $t("tips-p6")}}</p>
                </div>
                <div class="padding-bottom">
                    <h4>{{ $t("tips-h4-7")}}</h4>
                    <p>{{ $t("tips-p7")}}</p>
                </div>
            </div>
        </div>
    </b-container>
</template>

<script>
    import customMixin from '@/mixins/customMixin'
    export default {
        mixins:[ 
            customMixin
        ],
        data:function(){
            return{
                //
            }
        }
    }
</script>

<style lang="scss" scoped>
 .box{
    display:flex;
    justify-content: center;
    height:100vh;
    
 }
  .responsive-logo {
    width: 100%;
    max-width: 140px;
    height: auto;
}
.flex{
    display:flex;
}
.flex-row{
    flex-direction: row;
}
.flex-column{
    flex-direction: column;
    
}
p {
    text-align: justify;
}
@media screen and (max-width: 768px) {
    .box{
        padding:auto;
        margin-top:15vh;
        height:auto;
    }
    .responsive-logo{
        width:30vw;
    }
    .padding-bottom{
        padding-bottom: 0;
    }
}
</style>