<template>
  <div>
    
    <Homes/>
    <Esencia/>
    <Pasion/>
    <Proceso/>
    <Obras/>    
    <Tips/>   
    <Backtotop>
      <i class="fa fa-angle-double-up" aria-hidden="true" style="font-size:36px"/>
    </Backtotop>
    <div class="white-space">&nbsp;</div>
  </div>  
</template>

<script>
// @ is an alias to /src
import Homes from '@/components/Home.vue'
import Esencia from '@/components/Esencia'
import Pasion from '@/components/Pasion'
import Proceso from '@/components/Proceso'
import Obras from '@/components/Obras'
import Tips from '@/components/Tips'
import Backtotop from '@/components/Backtotop'
export default {
  name: 'Home',
  components:{
    Homes, Esencia, Pasion, Proceso, Obras, Tips, Backtotop
  }
}
</script>
<style lang="scss">
.white-space{
  height:25vh;
  display:flex
}
.fa {
  color:white;
}
@media screen and (max-width: 480px) {
  
}
</style>
