<template>
    <b-container id="Pasion" > 
    <div class="box" v-if="!isMobile()">
        <div class="flex flex-column"> 
                <div class="flex flex-row align-items-center">
                    <img class="responsive" src="/images/logoblack.png" alt="Joya Work Shop"> 
                    <h2>{{ $t("passion-h2")}}</h2>
                </div>
                <div class="flex align-items-start">
                    <h3>{{ $t("passion-h3")}}</h3>
                </div>
           
            <div class="flex mt-5">
                <div class="px-2">
                    <img src="/images/HepCedro_Amargo.png" alt="Cedro Amargo"> 
                    <h5>{{ $t("passion-h5_1") }}</h5>
                    <p>{{ $t("passion-p1_1") }}</p>
                    <p>{{ $t("passion-p1_2") }}</p>
                </div>
                <div class="px-2">
                    <img class="mr-5" src="/images/HepCaoba.png" alt="Caoba"> 
                    <h5>{{ $t("passion-h5_2") }}</h5>
                    <p>{{ $t("passion-p2_1") }}</p>
                    <p>{{ $t("passion-p2_2") }}</p>
                </div>
                <div class="px-2">
                    <img class="mr-5" src="/images/HepTeca.png" alt="Teca"> 
                    <h5> {{ $t("passion-h5_3")}} </h5>
                     <p>{{ $t("passion-p3_1") }}</p>
                    <p>{{ $t("passion-p3_2") }}</p>
                </div>
                <div class="px-2">
                    <img src="/images/HepRoble.png" alt="Roble"> 
                    <h5> {{ $t("passion-h5_4")}} </h5>
                    <p>{{ $t("passion-p4_1") }}</p>
                    <p>{{ $t("passion-p4_2") }}</p>
                </div>

            </div>
            <div class="flex mt-5">
                <div class="entendemos">
                   {{ $t("passion-div1")}}
                </div>
            </div>
            
            <ol class="flex flex-column mt-3 align-items-start flex-wrap">
                <li>{{ $t("passion-l1")}}</li>
                <li>{{ $t("passion-l2")}}</li>
                <li>{{ $t("passion-l3")}}</li>
            </ol>
            
        </div>
    </div> 
    <div class="box" v-else>
        <div class="flex flex-column"> 
            <div class="flex flex-row align-items-center">
                    <img class="responsive" src="/images/logoblack.png" alt="Joya Work Shop"> 
                    <h2>{{ $t("passion-h2") }}</h2>
                </div>
                <div class="flex align-items-start mt-3">
                    <h3>{{ $t("passion-h3") }}</h3>
                </div>
                <div class="mt-3">
                    <img src="/images/HepCedro_Amargo.png" alt="Cedro Amargo"> 
                    <h5>{{ $t("passion-h5_1") }}</h5>
                    <p>{{ $t("passion-p1_1") }}</p>
                    <p>{{ $t("passion-p1_2") }}</p>
                </div>
                 <div>
                    <img src="/images/HepCaoba.png" alt="Caoba"> 
                    <h5>{{ $t("passion-h5_2") }}</h5>
                    <p>{{ $t("passion-p2_1") }}</p>
                    <p>{{ $t("passion-p2_2") }}</p>
                </div>
                <div>
                    <img src="/images/HepTeca.png" alt="Teca"> 
                    <h5> {{ $t("passion-h5_3")}} </h5>
                     <p>{{ $t("passion-p3_1") }}</p>
                    <p>{{ $t("passion-p3_2") }}</p>
                </div>
                <div>
                    <img src="/images/HepRoble.png" alt="Roble"> 
                    <h5> {{ $t("passion-h5_4")}} </h5>
                    <p>{{ $t("passion-p4_1") }}</p>
                    <p>{{ $t("passion-p4_2") }}</p>
                </div>
        </div>    
    </div>   
    </b-container>
</template>

<script>
    import customMixin from '@/mixins/customMixin'
    export default {
        mixins:[ 
            customMixin
        ],
    }
</script>

<style lang="scss" scoped>
#Pasion{
    display:flex;
    flex-direction: column;
    justify-content: flex-start;
}
 .flex{
   display: flex;
 }
 
 .box{
    display:flex;
     align-items: center;
    justify-content: center;
    height:100vh;
    margin-top: -5vh;
    padding:0;
 }
 h2 {
     font-family: "DIN-Medium";
 }

.responsive {
    width: 100%;
    max-width: 140px;
    height: auto;
}
p {
     text-align: left;
}
h5 {
    text-align: left;
}
.entendemos{
    display:flex;
    justify-content:center;
    align-items: center;
    background-image: url('/images/fondodiv.png');
    width: 700px;
    height: 40px;
    background-size: 700px 40px;
    color: white;
}
li {
    text-align: left;
}
@media screen and (max-width: 768px) {
    .box{
        margin-top:5vh;
        height: auto;
    }
    .responsive{
        width:30vw;
    }
}
</style>
