<template>
    <b-container id="Obras">
        <div class="box" v-if="!isMobile()">
            <div class="flex flex-column w-100 mt-5">
                <div class="flex flex-row align-items-center justify-content-between ">  
                    <div class="flex flex-row align-items-center">
                        <img class="responsive-logo" src="/images/logoblack.png" alt="Joya Work Shop"> 
                        <h2>{{ $t("works-h2") }}</h2> 
                    </div>                  
                    <div class="flex flex-row   ">
                        <div class="flex flex-column mr-3 ml-3">
                            <img class="heptagonos" src="/images/Obras1hep.png" alt="Obras1">
                            <span>{{ $t("works-span1")}}</span>
                        </div>
                        <div class="flex flex-column mr-3">
                            <img class="heptagonos" src="/images/Obras2hep.png" alt="Obras2">
                            <span>{{ $t("works-span2")}}</span>
                        </div>
                        <div class="flex flex-column mr-3">
                            <img class="heptagonos" src="/images/Obras3hep.png" alt="Obras3">
                            <span>{{ $t("works-span3")}}</span>
                        </div>
                        <div class="flex flex-column mr-3 ">
                            <img class="heptagonos" src="/images/Obras4hep.png" alt="Obras4">
                            <span>{{ $t("works-span4")}}</span>
                        </div>
                    </div>
                </div>
                <div class="flex flex-row h-75 justify-content-center align-self-stretch mt-5">
                    <swiper class="swiper" :options="swiperOption">
                        <swiper-slide class="slide-1" ></swiper-slide>
                        <swiper-slide class="slide-2"></swiper-slide>
                        <swiper-slide class="slide-3"></swiper-slide>
                        <swiper-slide class="slide-4"></swiper-slide>
 
                        <div class="swiper-button-prev" slot="button-prev"></div>
                        <div class="swiper-button-next" slot="button-next"></div>
                    </swiper>
                
            </div>
        </div>
        </div>
        <div class="box" v-else>
            <div class="flex flex-column">
                <div class="flex flex-row align-items-center">
                    <img class="responsive-logo" src="/images/logoblack.png" alt="Joya Work Shop"> 
                    <h2>{{ $t("works-h2")}}</h2>  
                </div> 
                <div class="w-100">
                    <swiper class="swiper" :options="swiperOption">
                        <swiper-slide class="slide-1" ></swiper-slide>
                        <swiper-slide class="slide-2"></swiper-slide>
                        <swiper-slide class="slide-3"></swiper-slide>
                        <swiper-slide class="slide-4"></swiper-slide>
 
                        <div class="swiper-button-prev" slot="button-prev"></div>
                        <div class="swiper-button-next" slot="button-next"></div>
                    </swiper>  
                </div>     
            </div>
        </div>
    </b-container>
</template>

<script>
    import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
    import 'swiper/css/swiper.css'
    import customMixin from '@/mixins/customMixin'
    export default {
         mixins:[ 
            customMixin
        ],
        components: {
            Swiper,
            SwiperSlide
        },
        data: function(){
            return{
               swiperOption: {
                    spaceBetween: 30,
                    effect: 'fade',
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true
                    },
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev'
                    }
                }
            }//return
        }//data
    }//export default
</script>

<style lang="scss" scoped>

 .box{
    display:flex;
    justify-content: center;
    height:100vh;
    
 }
 .responsive-logo {
    width: 100%;
    max-width: 140px;
    height: auto;
}
.flex{
    display:flex;
}
.flex-row{
    flex-direction: row;
}
.flex-column{
    flex-direction: column;
    
}
.heptagonos{
    width:100%;
    height:auto;
    max-width:100px;
}
.swiper {
    height: auto;   
    width: 55vw;  
    height: 50vh;
    
  }
 .swiper swiper-slide{
      
       background-size: cover;
  }
  .slide-1 {
        background-image:url('/images/Obras1.png');
  }
  .slide-2 {
       background-image:url('/images/Obras2.png');
  }
  .slide-3 {
       background-image:url('/images/Obras3.png');
  }
  .slide-4 {
       background-image:url('/images/Obras4.png');
  }

  @media screen and (max-width: 768px) {
    .box{
        padding:auto;
        margin-top:50vh;
        height:auto;
    }
    .responsive-logo{
        width:30vw;
    }
    .swiper{
        width: 90vw;
    }
    .slide-1 {
        background-image:url('/images/Obras1.png');
        background-size:100% 100%;
    }
    .slide-2 {
       background-image:url('/images/Obras2.png');
       background-size:100% 100%;
    }
    .slide-3 {
       background-image:url('/images/Obras3.png');
        background-size:100% 100%;
    }
    .slide-4 {
       background-image:url('/images/Obras4.png');
       background-size:100% 100%;
    }
  }
@media (max-width: 768px) and (orientation: landscape) {
    .box{        
        height:auto;
        margin-top:auto;
    }
     .slide-1 {
        background-image:url('/images/Obras1.png');
        background-size:cover;
    }
    .slide-2 {
       background-image:url('/images/Obras2.png');
       background-size:cover;
    }
    .slide-3 {
       background-image:url('/images/Obras3.png');
        background-size:cover;
    }
    .slide-4 {
       background-image:url('/images/Obras4.png');
       background-size:cover;
    }
}
</style>