<template>
    <div>
        
        <a @click="scrollTop" v-show="visible" class="bottom-right">
            <slot></slot>
        </a>
    </div>
</template>

<script>
    export default {
        data () {
           return {
             visible: true
           }
         },
         methods: {
           scrollTop: function () {
             this.intervalId = setInterval(() => {
               if (window.pageYOffset === 0) {
                 clearInterval(this.intervalId)
               }
               window.scroll(0, window.pageYOffset - 50)
             }, 20)
           },
           scrollListener: function (e) {
             this.visible = window.scrollY > 50
           }
         },
         mounted: function () {
           window.addEventListener('scroll', this.scrollListener)
         },
         beforeDestroy: function () {
           window.removeEventListener('scroll', this.scrollListener)
         }
    }
</script>

<style lang="scss" scoped>
.bottom-right {
  position: fixed;
  bottom: 160px;
  right: 20px;
  cursor: pointer;
  z-index:2;

    border-radius: 50%;
    /*background-color: rgba(0, 0, 0, 0.55);*/
    background-image: url('/images/fondo_veta1.jpg');
    background-repeat: no-repeat;
    background-position-y: 83%;
    padding-top:5px;
    width:50px;
    height:50px;
}
</style>