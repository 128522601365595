<template>
 <div class="container-fluid footer" >
    <div v-if="!isMobile()" class="paragraph1">
        <img src="/images/logowhite.png" alt="Joya Work Shop" width="140px" height="139px"> 
        <div class="wrapper-column">
          <div class="flex">
            <span><i class="fa fa-phone" aria-hidden="true"></i> +507 394.8987</span>
            <a href="https://web.whatsapp.com/send?phone=50763367963" target="_blank"><span><i class="fa fa-mobile" aria-hidden="true"></i> +507 6336.7963</span></a>
            <span><i class="fa fa-envelope ml-3" aria-hidden="true"></i>info@joyaworkshop.com</span>
            <a href="https://www.instagram.com/joyaworkshop/" target="_blank"><span><i class="fa fa-instagram" aria-hidden="true"></i> joyaworkshop</span></a>
            <a href="https://www.facebook.com/Joya-Workshop-133173994102168/" target="_blank"><span><i class="fa fa-facebook ml-3" aria-hidden="true"></i> Joya Workshop</span></a>
          </div>
          <div class="flex">
            <a href="https://www.google.com/maps?q=9.048136,-79.461152&t=m&z=15" target="_blank"> <span>{{ $t("footer-address")}}</span> </a>
          </div>
        </div>
      </div>
      <div v-else class="paragraph1">
       <!--  <img src="/images/logowhite.png" alt="Joya Work Shop" width="100px" height="99px"> -->
        <div class="wrapper-column">
           <div class="flex justify-content-center">
            <span><i class="fa fa-phone mr-2" aria-hidden="true"></i></span>
            <a href="https://api.whatsapp.com/send?phone=50763367963" target="_blank"><span><i class="fa fa-mobile mr-2" aria-hidden="true"></i></span> </a>
            <span><i class="fa fa-envelope mr-2" aria-hidden="true"></i></span>
            <a href="https://www.instagram.com/joyaworkshop/" target="_blank"><span><i class="fa fa-instagram mr-2" aria-hidden="true"></i> </span></a>
            <a href="https://www.facebook.com/Joya-Workshop-133173994102168/" target="_blank"><span><i class="fa fa-facebook mr-2" aria-hidden="true"></i> </span></a>
          </div>
       
           <a href="https://www.google.com/maps?q=9.048136,-79.461152&t=m&z=15" target="_blank"> <span>{{ $t("footer-address")}}</span> </a>
          
        </div>

      </div>
    </div >
</template>

<script>
  import customMixin from '@/mixins/customMixin'
  export default {
    mixins:[ customMixin ],
    data(){
      return{
        //
      }
    }
  }
</script>

<style lang="scss" scoped>
.footer{
    position:fixed;
    left: 0;
    bottom: 0;

    background:  url('/images/fondo_veta1.jpg'); /* veta1   footer */
    background-repeat: no-repeat;
    background-position-y: 83%;
    background-size:100vw;
    /*background-size: 100%;*/
    object-fit:cover;
    object-position: -20% 0;

    height: 15vh;
    margin: 0 0 0 0;
    padding: 0 0 0 0;
    flex-shrink: 0;
    overflow:hidden;
    z-index: 1;
}
.paragraph1{
  display:flex;
  justify-content: center;
  align-items: center; 
  height:139px;
  
}
 .paragraph1 span {
    color:#ffffff;
    margin-right: 2%;
   
  }
 .wrapper-column{
   display:flex;
   flex-direction: column;
 /*  width:90%;*/
   
 }
 .flex{
     display:flex;
 }

 @media screen and (max-width: 768px) {
    .footer{
      width:100vw;
      height: 25vh;

      background:  url('/images/fondo_veta1.jpg'); /* veta1   footer */
      background-repeat: no-repeat;
      background-position-y: 90%;
      background-size:100vw;
      /*background-size: 100%;*/
      object-fit:cover;
      object-position: -20% 0;

    }
    .paragraph1{
      display:flex;
      justify-content: flex-start;
      height:139px;
      align-items: center;
    }
 }
 @media (max-width: 768px) and  (orientation: landscape) {
    .footer{
      width:100vw;
      height: 26vh;

      background:  url('/images/fondo_veta1.jpg'); /* veta1   footer */
      background-repeat: no-repeat;
      background-position-y: 90%;
      background-size:100vw;
      /*background-size: 100%;*/
      object-fit:cover;
      object-position: -20% 0;

    }
    .paragraph1{
      display:flex;
      justify-content: flex-start;
      height:auto;
      align-items: center;
    }
 }
</style>