<template>
    <b-container id="Proceso" > 
    <div class="box" v-if="!isMobile()">
        <div class="flex flex-column mb-5 "> 
                <div class="flex flex-row align-items-center">
                    <img class="responsive" src="/images/logoblack.png" alt="Joya Work Shop"> 
                    <h2>{{ $t("process-h2")}}</h2>
                </div>
               <div class="flex justify-content-around ml-5 mb-5">
                   <div class="flex flex-column mini-box">
                       <img class="img-box" src="/images/cotizacion.png">
                       <h5>{{ $t("process-h5-2") }}</h5>
                       <p>{{ $t("process-p2-1")}}</p>
                   </div>
                   <div class="flex flex-column mini-box ml-5">
                       <img class="img-box" src="/images/desarrollo.png">
                       <h5>{{ $t("process-h5-4") }}</h5>
                       <p>{{ $t("process-p4-1")}}</p>
                   </div>
                   <div class="flex flex-column mini-box ml-5">
                       <img class="img-box" src="/images/satisfacion.png">
                       <h5>{{ $t("process-h5-6") }}</h5>
                       <p>{{ $t("process-p6-1")}}</p>
                   </div>
               </div>

               <div class="trayecto ml-5">
                   .
               </div> 

               <div class="flex mt-5">
                   <div class="flex flex-column mini-box ">
                       <img class="img-box" src="/images/proyeccion.png">
                       <h5>{{ $t("process-h5-1") }}</h5>
                       <p>{{ $t("process-p1-1")}}</p>
                   </div>
                    <div class="flex flex-column mini-box ml-5">
                        <img class="img-box" src="/images/diseno.png">
                        <h5>{{ $t("process-h5-3") }}</h5>
                       <p>{{ $t("process-p3-1")}}</p>
                    </div>
                    <div class="flex flex-column mini-box ml-5">
                        <img class="img-box" src="/images/entrega.png">
                        <h5>{{ $t("process-h5-5") }}</h5>
                       <p>{{ $t("process-p5-1")}}</p>
                    </div>
               </div>
            
        </div>
    </div>
    <div class="box" v-else>
        <div class="flex flex-column">
            <div class="flex flex-row align-items-center">
                    <img class="responsive" src="/images/logoblack.png" alt="Joya Work Shop"> 
                    <h2>{{ $t("process-h2")}}</h2>
            </div>
            <div class="mt-3">
                       <img class="img-box" src="/images/proyeccion.png">
                       <h5>{{ $t("process-h5-1") }}</h5>
                       <p>{{ $t("process-p1-1")}}</p>
            </div>
            <div>
                       <img class="img-box" src="/images/cotizacion.png">
                       <h5>{{ $t("process-h5-2") }}</h5>
                       <p>{{ $t("process-p2-1")}}</p>
            </div>
            <div>
                        <img class="img-box" src="/images/diseno.png">
                        <h5>{{ $t("process-h5-3") }}</h5>
                        <p>{{ $t("process-p3-1")}}</p>
            </div>
            <div>
                       <img class="img-box" src="/images/desarrollo.png">
                       <h5>{{ $t("process-h5-4") }}</h5>
                       <p>{{ $t("process-p4-1")}}</p>
            </div>
            <div>
                       <img class="img-box" src="/images/entrega.png">
                       <h5>{{ $t("process-h5-5") }}</h5>
                       <p>{{ $t("process-p5-1")}}</p>
            </div>
            <div>
                       <img class="img-box" src="/images/satisfacion.png">
                       <h5>{{ $t("process-h5-6") }}</h5>
                       <p>{{ $t("process-p6-1")}}</p>
            </div>
           
            
            
        </div>
    </div>
    </b-container>
</template>

<script>
    import customMixin from '@/mixins/customMixin'
    export default {
        mixins:[ 
            customMixin
        ],
    }
</script>

<style lang="scss" scoped>
 .flex{
   display: flex;
 }
 .flex-column{
     flex-direction: column;
 }
 .box{
    display:flex;
     align-items: center;
    justify-content: center;
    height:100vh;
 }
 h2 {
     font-family: "DIN-Medium";
 }

.trayecto{
     display:flex;
    justify-content:center;
    align-items: center;
    background-image: url('/images/trayecto.png');
    width:80%;
    height: 20px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
}
.responsive {
    width: 100%;
    max-width: 140px;
    height: auto;
}
p {
     text-align: left;
}
h5 {
    text-align: left;
}
.entendemos{
    display:flex;
    justify-content:center;
    align-items: center;
    background-image: url('/images/fondodiv.png');
    width: 700px;
    height: 40px;
    background-size: 700px 40px;
    color: white;
}
li {
    text-align: left;
}
.mini-box{
    width:25%;
}
.img-box{
    object-fit: contain;
    align-self: flex-start;
}
@media screen and (max-width: 768px) {
    .box{
        padding:auto;
        margin-top:50vh;
    }
    .responsive{
        width:30vw;
    }
}
@media (max-width: 768px) and (orientation: landscape) {
    .box{
        height:auto;
    }
    .responsive{
        width:30vw;
    }
}
</style>
