<template>
  <div id="app">
    <div class="layout">
      <div class="content">
        <router-view/>
      </div>  
      <Footer></Footer>
    </div>
  </div>
</template>

<script>
import Footer from '@/components/Footer'
export default {
  name: 'App',
  components: {
    Footer
  },
  data: function(){
    return{
     
    }        
  }
}
</script>

<style lang="scss" >
@import './assets/sass/fonts.scss';
body{
  
  margin:0;
  padding:0;
  font-family: "DIN-Light";
  overflow-x: hidden;
}

#app {
 // font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;

}

.layout{
   display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  height: 100vh;

  margin:0;
  padding:0;
  
}
.content{
   flex: 1 0 auto;
}
</style>
