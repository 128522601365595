export default {
    methods: {
        maxWidth(ref) {
            if( ref > 1904 ) {
                return '90vh'
            }
            else if( ref > 1200 ) {
                return '68vh'
            }
        },
        isMobile() {
            if( screen.width <= 760 ) {
                console.log("mobile")
                return true;
            }
            else {
                return false;
            }
        }
    }
}