<template>
    <b-container class="fondo1" fluid>
      <div v-if="isMobile()" class="flex flex-column">
        <b-navbar toggleable type="dark" variant="dark">
          <b-navbar-brand href="#">Menu</b-navbar-brand>
          <b-navbar-toggle target="navbar-toggle-collapse">
            <template #default="{ expanded }">
              <i v-if="expanded" class="fa fa-angle-up"></i>
              <i v-else class="fa fa-angle-down"></i>
            </template>
          </b-navbar-toggle>

          <b-collapse id="navbar-toggle-collapse" is-nav>
            <b-navbar-nav class="ml-auto">
              <b-nav-item href="#Esencia">  {{ $t("menu-esencia") }}  </b-nav-item>
              <b-nav-item href="#Pasion">   {{ $t("menu-pasion") }}   </b-nav-item>
              <b-nav-item href="#Proceso">  {{ $t("menu-proceso") }}  </b-nav-item>
              <b-nav-item href="#Obras">    {{ $t("menu-obras") }}    </b-nav-item>
              <b-nav-item href="#Tips">     {{ $t("menu-tips") }}     </b-nav-item>
              <b-nav-item href="https://www.joyaworkshop.com/jwshop">         {{ $t("menu-shop") }}     </b-nav-item>
            </b-navbar-nav>
          </b-collapse>
        </b-navbar>
        <div class="lang">
          <a class="lang-text" v-on:click="changeLocale(es)"><flag iso="pa" /></a> /
          <a class="lang-text" v-on:click="changeLocale(en)"><flag iso="us" /></a>
        </div>
      </div>
       
        
        
        
      
      <div v-else class="flex flex-row align-items-center justify-content-center">
        <div id="nav">
          <a href="#Esencia">{{ $t("menu-esencia") }}</a> /
          <a href="#Pasion">{{ $t("menu-pasion")}}</a> /
          <a href="#Proceso">{{ $t("menu-proceso")}}</a> /
          <a href="#Obras">{{ $t("menu-obras")}}</a> /
          <a href="#Tips">{{ $t("menu-tips")}}</a> /
          <a href="https://www.joyaworkshop.com/jwshop">{{ $t("menu-shop")}}</a>
        </div>
        <div class="lang">
          <a class="lang-text" v-on:click="changeLocale(es)"><flag iso="pa" /></a> /
          <a class="lang-text" v-on:click="changeLocale(en)"><flag iso="us" /></a>
        </div>
      </div>
      <div class="logoH1">
         <img class="responsive" src="/images/logowhite.png" alt="Joya Work Shop"> 
         <h1 class="h1joyaworkshop">JOYA WORKSHOP</h1>
      </div>
      

      
    </b-container>
</template>

<script>
  import customMixin from '@/mixins/customMixin'
  export default {
    mixins:[ 
      customMixin
    ],
        data(){
          return{
              es:'es',
              en:'en',
              showNav: false
          }
        },
        
        computed:{
         
        },
        methods:{
          changeLocale(locale) {        
             this.$i18n.locale = locale
          },
           onResize() {
            if (window.innerWidth > 960) {
              //this.display = 5
              console.warn(window.innerWidth)
            } else {
              //this.display = 3
              console.warn(window.innerWidth)
            }
          }
        },
        created() {
           window.addEventListener('resize', this.onResize)
        },

        beforeDestroy() {
          window.removeEventListener('resize', this.onResize)
        },
  }
</script>

<style lang="scss" scoped>
.fondo1{
  display:flex;
  flex-direction:column;
  background-image: url('/images/fondo_veta1.jpg');
  background-size: 100%;
  height: 85vh;
  justify-content: space-around;
  border-bottom: none;
}

#nav {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-image: url('/images/divnav.png');
    
    width: 980px;
    height: 40px;
    align-self:center;
    padding-left:2%;
    padding-right:2%;

  a {
    font-weight: bold;
    color: #ffffff;
/*
    &.router-link-exact-active {
      color: #292728;
    }*/
    &:hover {
     color: #58595B;
    }
  }
}
.lang{
  margin-left:2%;
}

.lang-text {
    color:#ffffff;
    text-decoration: none;
  
    
}
.lang-text:hover{
   color:  #58595B;
}

.logoH1{
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  margin-top:-15vh;
}

 .flex{
   display: flex;
 }
 .flex-row{
   flex-direction: row;
 }
 .h1joyaworkshop{
    font-family: "DIN-Medium";
    color:white;
    font-size: 4rem;
    margin-top: -20%
 }
 .responsive {
   width: 100%;
  max-width: 560px;
  height: auto;
}
@media screen and (max-width: 480px) {
  .fondo1{
    justify-content: flex-start;
    background-repeat: no-repeat;
    background-size:  cover;
    height: 79vh;

  }
  .menu-mobile{
    color:white;
    margin-top:2%;
  }
  .Navbar__Link-toggle {
    align-self: flex-end;
    display: initial;
    position: absolute;
    cursor: pointer;
  }
  .logoH1{
    margin-top:0;
    
  }
  .responsive{
    width: 60vw;
    align-self: center;
    
  }
  .h1joyaworkshop{
    font-size: 3rem;
    margin-top:auto;
  }
}
@media  (max-width: 768px)  and  (orientation: landscape) {
  .fondo1{
    justify-content: flex-start;
    background-repeat: no-repeat;
    background-size:  cover;
    height: auto;
  }
  .logoH1{
    margin-top:-5%;
    justify-content: flex-start;
    flex-wrap: wrap;
    
  }
  .responsive{
    width: 35vw;
    align-self: center;
    
  }
  .h1joyaworkshop{
    font-size: 3rem;
    margin-top:-11%;
  }
  
}
</style>